.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #333;
    color: #e0e0e0;
    padding: 30px;
    border-radius: 10px;
    max-width: 400px;
    width: 80%;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .close-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #555;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .close-button:hover {
    background-color: #777;
  }
  