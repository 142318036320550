.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  justify-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

/* Mobile adjustment for grid */
@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }
}

.charm-card {
  background-color: #1e1e1e;
  border: 1px solid #333;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;
}

.charm-card:hover {
  transform: scale(1.05);
}

.card-image{
  max-width: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Falling images with chaos */
.falling-image img {
  animation: chaotic-fall 5s forwards ease-in-out;
}

/* Chaotic fall animation */
@keyframes chaotic-fall {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  20% {
    transform: translateY(100px) rotate(15deg);
  }
  40% {
    transform: translateY(300px) rotate(-20deg);
  }
  60% {
    transform: translateY(600px) rotate(30deg);
  }
  80% {
    transform: translateY(900px) rotate(-15deg);
  }
  100% {
    transform: translateY(1200px) rotate(45deg);
    opacity: 0;
  }
}

/* Apply random durations to each image */
.falling-image:nth-child(1) img {
  animation-duration: 5s;
}

.falling-image:nth-child(2) img {
  animation-duration: 6s;
}

.falling-image:nth-child(3) img {
  animation-duration: 4.5s;
}

.falling-image:nth-child(4) img {
  animation-duration: 5.5s;
}

.falling-image:nth-child(5) img {
  animation-duration: 6.5s;
}

/* Adjust rotation and movement per image to add randomness */
.falling-image:nth-child(odd) img {
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.falling-image:nth-child(even) img {
  animation-timing-function: ease-in-out;
}

.slider-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slider-controls button,
.slider-controls input[type="number"] {
  flex: 1;
  margin: 0 5px;
}

input[type="number"] {
  width: auto;
  text-align: center;
}

.slider-range {
  width: 100%;
}

.slider-range input[type="range"] {
  width: 100%;
}

.arrow-button {
  padding: 5px 10px;
  font-size: 14px;
  margin: 0 5px;
}

input[type="number"] {
  width: 80px;
}

.slider-range input[type="range"] {
  width: 100%;
}

.slider-title {
  margin-bottom: 5px;
  font-size: 14px;
  color: #b0b0b0;
}

input[type="number"] {
  width: 100px;
  background-color: #333;
  color: #e0e0e0;
  border: 1px solid #555;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
  -moz-appearance: textfield;
  }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.arrow-button {
  background-color: #333;
  color: #e0e0e0;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
  flex: 1;
}

.arrow-button:hover {
  background-color: #444;
  transform: scale(1.1);
}

.arrow-button:active {
  background-color: #555;
}

.inspect-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #333;
  color: #e0e0e0;
  text-decoration: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s, transform 0.3s;
  border: 1px solid #555;
  margin-top: 5px;
}

.inspect-button:hover {
  background-color: #444;
  transform: scale(1.05);
}

.inspect-button:active {
  background-color: #555;
  transform: scale(0.98);
}

.sort {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.sort label {
  font-size: 16px;
  font-weight: bold;
  color: #e0e0e0;
  margin-right: 10px;
}

.sort select {
  padding: 10px 20px;
  background-color: #333;
  color: #e0e0e0;
  border: 1px solid #555;
  border-radius: 4px;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
  outline: none;
}

.sort select:hover {
  background-color: #444;
}

.sort select:focus {
  transform: scale(1.05);
}