body {
  font-family: Arial, sans-serif;
  background-color: #121212;
  color: #e0e0e0;
  margin: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo-container {
  display: flex;
  margin-bottom: 20px;
}

.logo-container img {
  max-width: 200px;
  height: auto;
  border-radius: 8px;
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #b0b0b0;
}

footer a {
  color: #b0b0b0;
  text-decoration: none;
  margin-left: 10px;
}
footer a:hover {
  color: #e0e0e0;
}

footer i {
  font-size: 24px;
}